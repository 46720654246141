import Resizer from "react-image-file-resizer"
import moment from "moment"

import { zendeskUploadFiles } from "../../../services/zendeskService"
import { generateUploadFiles } from "../../../services/zendeskService"
import {
  checkIfHasRxRequired,
  accumulatePrice,
} from "../../Epharmacy/services/computations"

const MINIMUM_SIZE_TO_COMPRESS = 1048576

const generateFileName = (format, docType, index) => {
  const type = docType?.includes("SCPWD") ? "scpwd" : "rx"
  return `${type}_${moment().format("YYYYMMDD_HHmmss")}${
    index + 1 ? `_${docType?.includes("pop") ? "pop" : ""}${index + 1}` : "0"
  }`
}

export const isPdf = (imgData) => {
  return (
    imgData?.substring("data:".length, imgData.indexOf(";base64")) ===
    "application/pdf"
  )
}

export const checkIfUploadDisabled = (flow, state) => {
  const hasNoDocuments = state.documents.length === 0
  switch (flow) {
    case "epharmacy":
      const MAX_ORDER_TOTAL_LIMIT = 1000
      return (
        hasNoDocuments &&
        (checkIfHasRxRequired(state[flow].medicines) ||
          parseFloat(accumulatePrice(state[flow].medicines)) >=
            MAX_ORDER_TOTAL_LIMIT)
      )
    case "flexmed":
      return hasNoDocuments
    default:
      return !state.orderingFor
  }
}

export const isAlreadyUploaded = (file, filesUploaded) => {
  return filesUploaded.some((uploaded) => file.oldname === uploaded.oldname)
}

export const uploadDocument = (
  file,
  filesUploaded,
  docType,
  setFilesUploaded,
  callback,
  alreadyUploadedCallback,
  dispatch,
  index
) => {
  let pbmZendeskConfig = {
    email: process.env.GATSBY_ZENDESK_EMAIL,
    apiKey: process.env.GATSBY_ZENDESK_API_KEY,
    apiUrl: process.env.GATSBY_ZENDESK_API_URL,
  }
  let reader = new FileReader()
  if (file.type === "application/pdf") {
    reader.readAsDataURL(file)
    reader.onload = () => {
      const newFile = {
        path: reader.result,
        name: generateFileName(file.type, docType, index),
        filename: generateFileName(file.type, docType, index),
        oldname: file.name,
        type: docType,
        size: file.size,
      }
      if (isAlreadyUploaded(newFile, filesUploaded) && alreadyUploadedCallback)
        alreadyUploadedCallback(newFile)
      else {
        setFilesUploaded((fileList) => {
          let previousFilesUploaded = [...fileList]
          previousFilesUploaded.push(newFile)
          // if (callback) callback(previousFilesUploaded, newFile)
          return previousFilesUploaded
        })
        setTimeout(async () => {
          const UPLOADED_FILES = await zendeskUploadFiles(
            [newFile],
            null,
            pbmZendeskConfig
          )

          const GENERATED_FILE_TOKEN = await generateUploadFiles(UPLOADED_FILES)
          setFilesUploaded((fileList) => {
            let previousFilesUploaded = [...fileList]
            let newFileList = [
              ...previousFilesUploaded.filter(
                (fileInFileList) => fileInFileList.oldname !== newFile.oldname
              ),
              {
                ...newFile,
                path: null, // Note: this removes the path from the file after uploading to Zendesk
                token: GENERATED_FILE_TOKEN[0],
              },
            ]
            for (let i = 0; i < newFileList.length; i++) {
              if (newFileList[i]?.oldname.includes("pdf")) {
                newFileList[i].path = null
              }
            }
            if (docType?.includes("SCPWD"))
              dispatch({
                type: `SAVE_${docType}_ID`,
                payload: newFileList,
              })
            else
              dispatch({
                type: "SAVE_DOCUMENTS",
                payload: newFileList,
              })
            // if (callback) callback(newFileList)
            return newFileList
          })
          dispatch({
            type: "SHOW_TOAST",
            payload: {
              message: `${newFile?.oldname} has been uploaded.`,
              color: "success",
            },
          })
        }, 1000)
      }
    }
  } else
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      "JPEG",
      100,
      0,
      (uri) => {
        const newFile = {
          path: uri,
          name: generateFileName(file.type, docType, index),
          filename: generateFileName(file.type, docType, index),
          oldname: file.name,
          type: docType,
          size: file.size,
        }

        if (
          isAlreadyUploaded(newFile, filesUploaded) &&
          alreadyUploadedCallback
        )
          alreadyUploadedCallback(newFile)
        else {
          setFilesUploaded((fileList) => {
            let previousFilesUploaded = [...fileList]
            previousFilesUploaded.push(newFile)
            // if (callback) callback(previousFilesUploaded, newFile)
            return previousFilesUploaded
          })
          setTimeout(async () => {
            const UPLOADED_FILES = await zendeskUploadFiles(
              [newFile],
              null,
              pbmZendeskConfig
            )
            const GENERATED_FILE_TOKEN = await generateUploadFiles(
              UPLOADED_FILES
            )
            setFilesUploaded((fileList) => {
              let previousFilesUploaded = [...fileList]
              let newFileList = [
                ...previousFilesUploaded.filter(
                  (fileInFileList) => fileInFileList.oldname !== newFile.oldname
                ),
                {
                  ...newFile,
                  path: null, // Note: this removes the path from the file after uploading to Zendesk
                  token: GENERATED_FILE_TOKEN[0],
                },
              ]
              for (let i = 0; i < newFileList.length; i++) {
                if (newFileList[i]?.oldname.includes("pdf")) {
                  newFileList[i].path = null
                }
              }
              dispatch({
                type: "SAVE_DOCUMENTS",
                payload: newFileList,
              })
              // if (callback) callback(newFileList)
              return newFileList
            })
            dispatch({
              type: "SHOW_TOAST",
              payload: {
                message: `${newFile?.oldname} has been uploaded.`,
                color: "success",
              },
            })
          }, 1000)
        }
      },
      "base64"
    )
}

export const hasFilesUploaded = (files) => {
  if (files.length > 0) return true
  else return false
}
