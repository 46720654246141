import * as Yup from "yup"

export const consentAndAuthValidationSchema = (consentAndAuthorization) => {
  return Yup.object().shape({
    agreeToConsent: Yup.array().min(
      consentAndAuthorization?.length || 1,
      "This field is required"
    ),
  })
}

export const consentAndAuthInitialValues = {
  consentAndAuthorization: [],
}
