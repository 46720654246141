import React, { useContext } from "react"
import { Formik, Form } from "formik"

import Section from "../Elements/Section"
import FormCheckbox from "../Elements/Form/FormCheckbox"

import { consentAndAuthValidationSchema } from "./utils/formData"
import { AppContext } from "../../context/AppContext"

const termsAndConditionBlurb = (
  <span>
    I agree to the{" "}
    <a href="/terms-and-conditions" target="_blank">
      terms and conditions
    </a>
    .
  </span>
)

const couponAuthorizationBlurb = (
  <span>
    I authorize MedGrocer's HR department to deduct the amount in excess to my
    order from my salary.
  </span>
)

const ConsentAndAuthorization = ({ children, flow, handleOnSubmit }) => {
  const { state, dispatch } = useContext(AppContext)
  const {
    epharmacy: { coupon },
  } = state

  let consentAndAuthorizations = [termsAndConditionBlurb]
  let websiteDescription = coupon?.websiteDescription || ""

  if (websiteDescription?.includes("Free quarterly coupons for MedGrocer.com"))
    consentAndAuthorizations = [
      couponAuthorizationBlurb,
      termsAndConditionBlurb,
    ]

  return (
    <Section title="Consent and Authorization">
      <div className="mx-2">
        <Formik
          initialValues={state[flow]}
          validationSchema={consentAndAuthValidationSchema(
            consentAndAuthorizations
          )}
          onSubmit={handleOnSubmit}
        >
          {({ values }) => (
            <Form>
              <FormCheckbox
                name="agreeToConsent"
                values={values.agreeToConsent}
                options={consentAndAuthorizations}
                onChange={(event) =>
                  dispatch({
                    type: `SAVE_AGREE_${flow.toUpperCase()}`,
                    payload: event.target.checked
                      ? [termsAndConditionBlurb]
                      : [],
                  })
                }
                hideOptional
              />
              {children}
            </Form>
          )}
        </Formik>
      </div>
    </Section>
  )
}

export default ConsentAndAuthorization
