import React from "react"
import { navigate } from "gatsby"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

import Button from "../Elements/Button"
import styles from "../Summary/utils/summary.module.scss"

const EditDetailsButton = ({ route, previousRoute, className, children }) => (
  <Button
    onClick={() => navigate(route, { state: { previousRoute } })}
    size="small"
    variant="outlined"
    className={classNames(styles["section__buttonBorderless"], className || "")}
  >
    <span className="icon">
      <FontAwesomeIcon icon={faEdit} />
    </span>
    <span>{children || "Edit"}</span>
  </Button>
)

export default EditDetailsButton
